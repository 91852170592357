/* eslint-disable no-param-reassign */
/* eslint-disable radix */
import http from 'axios';
import { SubmissionError } from 'redux-form';
import { enhancedBasketSelector } from 'serviceInBasket/helpers/selectors';
import { IStoreState } from 'types/store';
import { googleTagManager } from '_helpers/googleTagManager';
import { appendIfInList } from '../../../_helpers/utils';
import { appConfig } from '../../../config';
import {
  axiosConfigSelector,
  currentBookingId,
} from '../../../authorization/selectors';
import { store } from '../../../store';
import { mapBackendErrorsToFields } from '../../../_helpers/reduxFormHelpers';
import { setPaymentMethod } from '../../actions';
import { getAllServices } from '../../../services/actions';
import { selectUpToDateBasket } from '../../../basket/selectors/selectBasket';

function mapValuePathToRequestJsonPath(valuePath) {
  switch (valuePath) {
    case 'citizenship':
    case 'personalId':
      return `collectorInfo.${valuePath}`;

    default:
      return appendIfInList(
        appConfig.contactDetailsFields,
        'contactDetails.'
      )(valuePath);
  }
}

export async function submitPaymentDetails(values, paymentData) {
  // Render of received snippet is in fact implicit page change
  // So we need to scroll back to top
  window.scroll(0, 0);

  try {
    const response = await http.post(
      `/retail-gateway/api/tools/payment`,
      { ...paymentData, type: 'REDIRECT' },
      axiosConfigSelector(store.getState())
    );

    if (response.data.responseType === 'REDIRECT') {
      beginCheckoutEvent(); // Added for A/B-Test
      window.location.href = response.data.redirectUrl;
    } else {
      store.dispatch(setPaymentMethod(response.data));
    }
  } catch (error) {
    if (error.response) {
      const {
        data: { errorCode, cmsKeyForUserError, detail },
      } = error.response;

      if (errorCode === 'VALIDATION_ERROR') {
        // Maps backend validation errors to fields
        const { validationErrors } = mapBackendErrorsToFields(
          values,
          detail,
          mapValuePathToRequestJsonPath
        );

        throw new SubmissionError(validationErrors);
      }

      if (errorCode === 'PAYMENT_ERROR') {
        // Most likely happened due to price change
        // Updating basket state
        const state = store.getState();
        const bookingId = currentBookingId(state);

        store.dispatch(getAllServices(bookingId, selectUpToDateBasket(state)));
      }

      throw new SubmissionError({
        _error: cmsKeyForUserError || 'Unknown error',
      });
    }
    throw new SubmissionError({
      _error: 'CMS_GENERIC_ERROR_REQ_FAILED',
    });
  }
}

function beginCheckoutEvent() {
  const state: IStoreState = store.getState();
  const basket = enhancedBasketSelector(state);
  if (basket.validationStatus === 'VALID') {
    googleTagManager.pushToDataLayer({
      event: 'beginCheckout',
      ecommerce: {
        currencyCode: basket.currency,
        checkout: {
          products: Object.values(
            basket.serviceInstances
              .map(service => ({
                name: service.name,
                id: service.serviceCode,
                price: service.price,
                category: service.addedAs,
                quantity: service.quantity,
              }))
              .reduce((mapped, service) => {
                const { quantity, ...rest } = service;
                const key = JSON.stringify(rest);
                if (!mapped[key]) {
                  mapped[key] = service;
                } else {
                  mapped[key].quantity += quantity;
                }
                return mapped;
              }, {})
          ),
        },
      },
    });
  }
}
